* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  background: #f9fafc !important;
  font-size: 0.875rem !important;
  font-weight: 400 v !important;
  line-height: 1.6 !important;
}

/* scrollbar style */
/* --> first change scrollbar aggrid and then the general one */
.ag-root ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 114, 114);
}

/* initial loading spinner */
.spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* fadeIn effect --> used in sidebar */
.fadeIn {
  -webkit-animation: fadein 0.8s cubic-bezier(1, 0, 1, 1);
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.8s cubic-bezier(1, 0, 1, 1);
  /* Firefox < 16 */
  -ms-animation: fadein 0.8s cubic-bezier(1, 0, 1, 1);
  /* Internet Explorer */
  -o-animation: fadein 0.8s cubic-bezier(1, 0, 1, 1);
  /* Opera < 12.1 */
  animation: fadein 0.8s cubic-bezier(1, 0, 1, 1);
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* fadeInLinear effect --> used in sidebarMobile */
.fadeInLinear {
  -webkit-animation: fadeinlinear 0.4s linear;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinlinear 0.4s linear;
  /* Firefox < 16 */
  -ms-animation: fadeinlinear 0.4s linear;
  /* Internet Explorer */
  -o-animation: fadeinlinear 0.4s linear;
  /* Opera < 12.1 */
  animation: fadeinlinear 0.4s linear;
}

@keyframes fadeinlinear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinlinear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinlinear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinlinear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dropdownItem:focus {
  background-color: #dcdcdc !important;
}

.dropdownItem:hover {
  color: #262626 !important;
  background-color: #f9f9f9;
}

.dropdownItem:hover>.dropdownItemInner {
  color: #4b4b4b !important;
}

.blackOnHover:hover {
  color: #262626 !important;
  transition: 0.2s;
}

.blackOnHover:hover>.blackOnHoverInner {
  color: #262626 !important;
  transition: 0.2s;
}

.underlineOnHover:hover {
  text-decoration: underline !important;
  transition: 0.2s;
}

.underlineOnHover:hover>.underlineOnHoverInner {
  text-decoration: underline !important;
  transition: 0.2s;
}

/* used in the menus of the sidebar to avoid menus to be selectable (ie when highlight on drag of the mouse) */
.noSelect {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Old versions of Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
}

.dropdownItemSidebarClosed {
  background-color: white !important;
}

.dropdownItemSidebarClosed:focus {
  background-color: white !important;
}

.dropdownItemSidebarClosed:hover {
  color: #4b4b4b !important;
  background-color: #f9f9f9;
}

/* Card of reactstrap change position to avoid bugs */
.card {
  position: static !important;
}

/* load page effect */
.loadPageEffect {
  -webkit-animation: loadpageeffect 0.5s linear;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: loadpageeffect 0.5s linear;
  /* Firefox < 16 */
  -ms-animation: loadpageeffect 0.5s linear;
  /* Internet Explorer */
  -o-animation: loadpageeffect 0.5s linear;
  /* Opera < 12.1 */
  animation: loadpageeffect 0.5s linear;
}

@keyframes loadpageeffect {
  from {
    opacity: 0;
    transform: translate(10px);
  }

  to {
    opacity: 1;
    transform: translate(0px);
  }
}

/* modal text of SuccessAlert() InfoAlert() and ErrorAlert() */
.swal2-title {
  font-size: 22px !important;
}

/* menu in Nav tabs should be gray, not blue, and set the active one to black */
.nav-link {
  color: gray !important;
}

.nav-link.active {
  color: #1976d2 !important;
}

.blackNav {
  color: black !important;
}

/* react-dropzone */
.dropzone {
  border: 2px dashed #e3e3e3;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone:hover {
  border-color: #4eacfe;
  background: rgba(76, 192, 243, 0.1);
}

.dropzone:active {
  border-color: #4eacfe;
  background: rgba(76, 192, 243, 0.1);
}

/* aggrid tables style */
.ag-filter-apply-panel-button {
  color: #2569cf;
  border-color: #5997f0;
  border-radius: 5px;
  background-color: white;
  padding: 0px 5px;
}

/* image overlay */
.containerImgUploaded {
  position: relative;
  cursor: pointer;
}

.overlayAvatar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #b9b9b9;
}

.overlayImgRect {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0;
  transition: 0.5s ease;
  background-color: #b9b9b9;
}

.containerImgUploaded:hover .overlayAvatar,
.containerImgUploaded:hover .overlayImgRect {
  opacity: 1;
}

.textAvatar {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.textImgRect {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.week-day {
  background-color: #f0fdf1;
}

.week-end {
  background-color: rgba(249, 231, 229, 0.5);
}

.presenze-anagrafica {
  background-color: #f0fcfd;
}

.conducenti-anagrafica {
  background-color: #f0fcfd;
}

.cellCursor {
  cursor: pointer;
}

/* link in text email */
.linkNotAllowed .MuiInputBase-input {
  cursor: not-allowed !important;
  color: #909090 !important;
}

/* fade in simple  */
.fadeInSimple {
  animation: fadeInSimple 0.7s linear;
}

@keyframes fadeInSimple {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* website */

.websitePageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* rotate effect for images */
.rotateImg {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.blueOnHover:hover {
  color: blue;
}

.swal2-container {
  z-index: 20000 !important;
}